<template>
    <v-data-table
        :headers="headers"
        :items="address"
        :loading="loading"
        :options.sync="options"
        :server-items-length="totalDesserts"
        class="elevation-1"
        :items-per-page="itemsPerPage"
        :footer-props="{
            itemsPerPageOptions: [25, 50, 100, 200, 500]
        }"
        @click:row="editItem"
        dense
    >
        <template v-slot:top>
            <v-toolbar flat dense>
                <v-toolbar-title>Lista ulic</v-toolbar-title>
                <v-spacer/>
                <v-dialog v-model="dialog" max-width="700px">
                    <template v-slot:activator="{ on }">
                        <v-btn
                            color="primary"
                            tile
                            dark
                            small
                            v-on="on"
                            @click="$refs.form ? $refs.form.reset() : null"
                        >
                            <v-icon class="mr-1">mdi-home-plus</v-icon> Dodaj adres
                        </v-btn>
                    </template>
                    <v-card>
                        <v-form
                            autocomplete="off"
                            ref="form"
                            @submit.prevent="save"
                            v-model="validSaveDataAddress"
                            lazy-validation
                        >
                            <v-card-title>
                                <span class="headline">{{ formTitle }}</span>
                            </v-card-title>

                            <v-card-text>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" sm="6" md="5" class="py-0">
                                            <v-text-field
                                                v-model="editedItem.city"
                                                label="Miasto"
                                                :error-messages="errors.city"
                                                :rules="[v => !!v || 'Pole obowiązkowe!']"
                                                outlined
                                                dense
                                            />
                                        </v-col>
                                        <v-col cols="12" sm="6" md="7" class="py-0">
                                            <v-text-field
                                                v-model="editedItem.street"
                                                label="Adres"
                                                :error-messages="errors.street"
                                                outlined
                                                dense
                                            />
                                        </v-col>
                                        <v-col cols="12" sm="6" md="2" class="py-0">
                                            <v-text-field
                                                v-model="editedItem.number_from"
                                                :disabled="(editedItem.s_whole === 1)"
                                                label="Nr. od"
                                                :error-messages="errors.number_from"
                                                outlined
                                                dense
                                            />
                                        </v-col>
                                        <v-col cols="12" sm="6" md="2" class="py-0">
                                            <v-text-field
                                                v-model="editedItem.number_to"
                                                :disabled="(editedItem.s_whole === 1)"
                                                label="Nr. do"
                                                :error-messages="errors.number_to"
                                                outlined
                                                dense
                                            />
                                        </v-col>
                                        <v-col cols="12" sm="6" md="4" class="py-0">
                                            <v-select
                                                v-model="editedItem.s_even"
                                                :items="evenOptions"
                                                item-text="text"
                                                item-value="value"
                                                label="Numery (nie)parzyste"
                                                outlined
                                                dense
                                            />
                                        </v-col>
                                        <v-col cols="12" sm="6" md="4" class="py-0">
                                            <v-switch
                                                color="blue"
                                                v-model="editedItem.s_whole"
                                                label="Cała ulica"
                                                class="mt-1"
                                                false-value="0"
                                                true-value="1"
                                                dense
                                            />
                                        </v-col>
                                        <v-col cols="6" class="py-0">
                                            <v-autocomplete
                                                clearable
                                                auto-select-first
                                                :items="stopsMap"
                                                v-model="editedItem.stop1"
                                                item-value="name"
                                                item-text="name"
                                                :rules="[v => !!v || 'Pole obowiązkowe!']"
                                                outlined
                                                label="Postój 1"
                                                dense
                                            />
                                        </v-col>
                                        <v-col cols="6" class="py-0">
                                            <v-autocomplete
                                                clearable
                                                auto-select-first
                                                :items="stopsMap"
                                                v-model="editedItem.stop2"
                                                item-value="name"
                                                item-text="name"
                                                outlined
                                                label="Postój 2"
                                                dense
                                            />
                                        </v-col>
                                        <v-col cols="6" class="py-0">
                                            <v-autocomplete
                                                clearable
                                                :items="stopsMap"
                                                v-model="editedItem.stop3"
                                                item-value="name"
                                                item-text="name"
                                                outlined
                                                label="Postój 3"
                                                dense
                                            />
                                        </v-col>
                                        <v-col cols="6" class="py-0">
                                            <v-autocomplete
                                                clearable
                                                auto-select-first
                                                :items="stopsMap"
                                                v-model="editedItem.stop4"
                                                item-value="name"
                                                item-text="name"
                                                outlined
                                                label="Postój 4"
                                                dense
                                            />
                                        </v-col>
                                        <v-col cols="6" class="py-0">
                                            <v-autocomplete
                                                clearable
                                                auto-select-first
                                                :items="stopsMap"
                                                v-model="editedItem.stop5"
                                                item-value="name"
                                                item-text="name"
                                                outlined
                                                label="Postój 5"
                                                dense
                                            />
                                        </v-col>
                                        <v-col cols="6" class="py-0">
                                            <v-autocomplete
                                                clearable
                                                auto-select-first
                                                :items="stopsMap"
                                                v-model="editedItem.stop6"
                                                item-value="name"
                                                item-text="name"
                                                outlined
                                                label="Postój 6"
                                                dense
                                            />
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>

                            <v-card-actions>
                                <v-spacer/>
                                <v-btn color="error" small outlined tile @click="close">Anuluj</v-btn>
                                <v-btn
                                    tile
                                    small
                                    color="primary"
                                    type="submit"
                                    :disabled="!validSaveDataAddress">Zapisz</v-btn>
                            </v-card-actions>
                        </v-form>
                    </v-card>
                </v-dialog>
                <v-btn
                    to="/"
                    class="ml-3"
                    dark
                    small>
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card tile class="search-card px-3 mb-3">
                <v-form
                    autocomplete="off"
                    @submit.prevent="actionSearch">
                    <v-card-subtitle class="text-uppercase">
                        Wyszukiwarka
                    </v-card-subtitle>
                    <v-card-text class="pb-0">
                        <div class="py-0">
                            <v-row>
                                <v-col cols="12" sm="4">
                                    <v-autocomplete
                                        v-model="searchCity"
                                        :items="cityMap"
                                        label="Miasto"
                                        dense
                                        outlined
                                        hide-details
                                        clearable
                                        auto-select-first
                                        prepend-icon="mdi-city"
                                        aria-autocomplete="none"
                                    />
                                </v-col>
                                <v-col cols="12" sm="4">
                                    <v-autocomplete
                                        v-if="searchCity"
                                        v-model="searchStreet"
                                        :items="streetsMap[searchCity]"
                                        label="Adres"
                                        dense
                                        outlined
                                        hide-details
                                        clearable
                                        auto-select-first
                                        prepend-icon="mdi-home-floor-a"
                                        aria-autocomplete="none"
                                    />
                                    <v-text-field
                                        v-else
                                        v-model="searchStreet"
                                        label="Adres"
                                        dense
                                        outlined
                                        hide-details
                                        prepend-icon="mdi-home-floor-a"
                                        aria-autocomplete="none"
                                    />
                                </v-col>
                                <v-col cols="12" sm="2">
                                    <v-text-field
                                        v-model="searchNumberFrom"
                                        prepend-icon="mdi-home-floor-1"
                                        label="Numer od"
                                        hide-details
                                        aria-autocomplete="none"
                                        dense
                                        outlined
                                    />
                                </v-col>
                                <v-col cols="12" sm="2">
                                    <v-text-field
                                        v-model="searchNumberTo"
                                        prepend-icon="mdi-home-floor-2"
                                        label="Numer do"
                                        hide-details
                                        aria-autocomplete="none"
                                        dense
                                        outlined
                                    />
                                </v-col>
                            </v-row>
                        </div>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn outlined @click="resetSearch" class="ml-auto">RESETUJ</v-btn>
                        <v-btn color="primary" tile type="submit"><v-icon class="mr-1">mdi-magnify</v-icon> SZUKAJ</v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </template>
        <template v-slot:[`item.id`]="{ item }">
            {{ item.id }}
        </template>
        <template v-slot:[`item.number`]="{ item }">
            {{ item.number }}
        </template>
        <template v-slot:[`item.name`]="{ item }">
            <strong>{{ item.short_name }}</strong><br />
            {{ item.name }}
        </template>
        <template v-slot:[`item.street`]="{ item }">
            {{ item.street }}
            <v-btn
                tile
                text
                small
                class="ml-2"
                @click.stop="fastSearch('street', item)"
            >
                <v-icon>mdi-crosshairs</v-icon>
            </v-btn>
        </template>
        <template v-slot:[`item.s_even`]="{ item }">
          <v-chip
            v-if="evenOptions[item.s_even]"
            label
            small
            :color="evenOptions[item.s_even].color"
          >
            {{evenOptions[item.s_even].text}}
          </v-chip>
          <v-chip v-else small label>brak</v-chip>
        </template>
        <template v-slot:[`item.s_whole`]="{ item }">
          <v-chip v-if="item.s_whole === '1'" color="primary" small label>Tak</v-chip>
          <v-chip v-else small label>Nie</v-chip>
        </template>
        <template v-slot:[`item.action`]="{ item }">
            <div class="d-flex">
                <v-btn tile text small @click="editItem(item)"><v-icon>mdi-pencil</v-icon></v-btn>
                <v-btn tile text small @click="deleteItem(item)"><v-icon>mdi-trash-can</v-icon></v-btn>
            </div>
        </template>
    </v-data-table>
</template>

<script>
    import Vue from "vue";

    export default {
        name: "Address",
        props: {
            message: {
                type: [String, Object],
                required: false
            },
        },
        data: () => ({
            search: false,
            searchCity: null,
            searchStreet: '',
            searchNumberFrom: '',
            searchNumberTo: '',
            totalDesserts: 0,
            desserts: [],
            page: 0,
            itemsPerPage: 100,
            dialog: false,
            headers: [
                { text: 'ID', value: 'id', sortable: false },
                { text: 'Miasto', value: 'city', sortable: false },
                { text: 'Adres', value: 'street', sortable: false },
                { text: 'Numery od', value: 'number_from', sortable: false },
                { text: 'Numery  do', value: 'number_to', sortable: false },
                { text: 'Cała ulica', value: 's_whole', sortable: false },
                { text: 'Numery', value: 's_even', sortable: false },
                { text: '', value: 'action', sortable: false },
            ],
            evenOptions: [
              {
                value: '0',
                text: 'wszystkie',
                color: 'primary'
              },
              {
                value: '1',
                text: 'nieparzyste',
                color: 'warning'
              },
              {
                value: '2',
                text: 'parzyste',
                color: 'error'
              },
            ],
            editedItem: {
                city: null,
                street: null,
                number_from: null,
                number_to: null,
                s_even: null,
                s_whole: null,
                stop1: null,
                stop2: null,
                stop3: null,
                stop4: null,
                stop5: null,
                stop6: null,
            },
            defaultItem: {
                city: null,
                street: null,
                number_from: null,
                number_to: null,
                s_even: null,
                s_whole: null,
                stop1: null,
                stop2: null,
                stop3: null,
                stop4: null,
                stop5: null,
                stop6: null,
            },
            options: {},
            loading: true,
            address: [],
            editedIndex: -1,
            errors: [],
            validSaveDataAddress: true
        }),
        computed: {
            cityMap() {return this.$store.state.socket.cityMap},
            streetsMap() {return this.$store.state.socket.streetsMap},
            stopsMap () {return this.$store.state.socket.stopsMap},
            formTitle () {
                return this.editedIndex === -1 ? 'Nowy adres' : 'Edytuj adres - ' + this.editedItem.city + ' ' + this.editedItem.street
            },
        },
        watch: {
            message: function (val) {
                if(typeof val.type !== 'undefined' && val.type === 'data_update' && val.data_type === 'streets_map') {
                    this.getDataFromApi();
                }
            },
            dialog (val) {
                val || this.close()
            },
            options: {
                handler () {
                    this.getDataFromApi()
                        .then(data => {
                            this.desserts = data.items;
                            this.totalDesserts = data.total;
                            this.itemsPerPage = data.itemsPerPage;
                            this.page = data.page;
                        })
                },
                deep: true,
            },
        },
        methods: {
            fastSearch(field, search) {
                this.resetFieldSearch();
                switch (field) {
                    case 'street':
                        this.searchCity = search.city;
                        this.searchStreet = search.street;
                        break;
                }
                this.actionSearch();
            },
            getDataFromApi () {
                this.loading = true;
                return new Promise((resolve) => {
                    let { page, itemsPerPage } = this.options;

                    if(this.search)
                        page = 0;

                    this.$http.get('p/ss/l', {
                        params: {
                            per_page: itemsPerPage,
                            page: page,
                            city: this.searchCity,
                            street: this.searchStreet,
                            number_from: this.searchNumberFrom,
                            number_to: this.searchNumberTo,
                        }
                    }) // Pobieranie listy adresów
                        .then((response) => {
                            this.address = response.data.data;

                            let items = this.address;
                            const total = parseFloat(response.data.total);

                            if (itemsPerPage > 0) {
                                items = items.slice((page - 1) * itemsPerPage, page * itemsPerPage)
                            }

                            resolve({
                                items,
                                total,
                                itemsPerPage,
                                page
                            });
                        })
                        .catch(() => {
                            this.$notify({
                                group: 'foo',
                                type: 'error',
                                title: 'Wystąpił błąd.',
                                text: 'Podczas pobierania adresów.'
                            });
                        })
                        .finally(() => {
                            this.loading = false;
                            this.search = false;
                        });
                })
            },
            deleteItem (item) {
                confirm('Czy na pewno chcesz usunąć ten adres?') && this.$http.post('p/ss/r/' + item.id)
                    .then(() => {
                        this.$notify({
                            group: 'foo',
                            type: 'success',
                            text: 'Adres został usunięty!'
                        });
                        const removeID = this.address.indexOf(item);
                        this.address.splice(removeID, 1);
                        this.close();
                    })
                    .catch(() => {
                        this.$notify({
                            group: 'foo',
                            type: 'error',
                            title: 'Wystąpił błąd.',
                            text: 'Podczas usuwania adresu.'
                        });
                    });
            },
            editItem (item) {
                this.editedIndex = item.id;
                this.$http.post('p/ss/g/' + this.editedIndex) // Pobieranie danych o adresie
                    .then((response) => {
                        this.editedItem = response.data;
                        this.dialog = true
                    })
                    .catch((error) => {
                        this.$notify({
                            group: 'foo',
                            type: 'error',
                            title: 'Wystąpił błąd.',
                            text: 'Podczas pobierania danych.'
                        });
                        this.errors = error.response.data.errors;
                    });
            },
            close () {
                this.dialog = false;
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1
            },
            save () {
                if(this.$refs.form.validate()) {
                    if (this.editedIndex > -1) {
                        this.$http.post('p/ss/e/' + this.editedItem.id, this.editedItem) // Zapis adresu
                            .then(() => {
                                this.$notify({
                                    group: 'foo',
                                    type: 'success',
                                    text: 'Dane adresu zostały zaktualizowane!'
                                });
                                this.getDataFromApi();
                                Vue.prototype.$socket.sendObj({action:'update_4_all_streets_map'});
                                this.close();
                            })
                            .catch((error) => {
                                this.$notify({
                                    group: 'foo',
                                    type: 'error',
                                    title: 'Wystąpił błąd.',
                                    text: 'Podczas aktualizowania adresu.'
                                });
                                this.errors = error.response.data.errors;
                            });
                    } else {
                        this.$http.post('p/ss/c', this.editedItem) // Zapis adresu
                            .then(() => {
                                this.$notify({
                                    group: 'foo',
                                    type: 'success',
                                    text: 'Dodano poprawnie nowy adres!'
                                });
                                this.address.push(this.editedItem);
                                Vue.prototype.$socket.sendObj({action:'update_4_all_streets_map'});
                                this.close();
                            })
                            .catch((error) => {
                                this.$notify({
                                    group: 'foo',
                                    type: 'error',
                                    title: 'Wystąpił błąd.',
                                    text: 'Podczas zapisu nowego adresu.'
                                });
                                this.errors = error.response.data.errors;
                            });
                    }
                } else {
                    this.$notify({
                        group: 'foo',
                        type: 'warn',
                        text: 'Popraw dane w formularzu.'
                    });
                }
            },
            actionSearch () {
                this.search = true;
                this.getDataFromApi();
            },
            resetFieldSearch() {
                this.searchCity = '';
                this.searchStreet = '';
                this.searchNumberFrom = '';
                this.searchNumberTo = '';
            },
            resetSearch () {
                this.resetFieldSearch();
                this.getDataFromApi();
            }
        },
    }
</script>

<style lang="scss">
    .search-card {
        background-color: #2f2f2f;
    }
</style>
